@import "../../assets/scss/variable.scss";
.label,
.oneLineLabel {
  display: inline-block;
  vertical-align: middle;
  // font-weight: 500 !important;
  text-transform: uppercase;
  &:before {
    content: "";
    display: inline-flex;
    width: 50px;
    height: 2px;
    background-color: $yellow;
    position: relative;
    margin-right: 15px;
    top: -4px;
  }
}

.label {
  &::after {
    content: "";
    display: inline-flex;
    width: 50px;
    height: 2px;
    background-color: $yellow;
    position: relative;
    margin-left: 15px;
    top: -4px;
  }
}
