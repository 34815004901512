@import "./variable.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  // padding: 10px;
  margin: 0;
  font-family: "Raleway", sans-serif !important;
  word-spacing: 8px;
  letter-spacing: 2px;
  font-weight: 600;
  color: black;
  background-color: whitesmoke;
  line-height: 30px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
