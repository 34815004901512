@import "../../../assets/scss/variable.scss";
.container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: $blue;
}

.container:hover .overlay {
  opacity: 1;
}

.socialDiv {
  content: "";
  width: 50px;
  height: 2px;
  display: block;
  background-color: #e4c47f;
  position: relative;
  top: 10px;
  margin: 0 auto;
}
